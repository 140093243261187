export default {
    meta: {
        label: 'Tiếng Việt'
    },
    data: {
        'vi': {
            97: [['👚', 'áo sơ mi'], ['🧥', 'áo choàng ngoài'], ['🧥', 'áo khoác']],
            98: [['🐄', 'bò'], ['🦋', 'bươm bướm'], ['🌽', 'bắp'], ['🍺', 'bia'], ['⚽️', 'bóng'], ['⚽️', 'banh'], ['🏖️', 'bờ biển']],
            99: [['🦀', 'cua'], ['🍚', 'cơm'], ['🐟', 'cá'], ['🌴', 'cây'], ['🐅', 'cọp'], ['🐁', 'chuột'], ['🐕', 'chó'], ['🐏', 'cừu'], ['🐦', 'chim'], ['🍊', 'trái cam'], ['🍋', 'chanh'], ['🍌', 'chuối'], ['☕️', 'cà phê'], ['👣', 'chân']],
            100: [['🐐', 'dê'], ['🦗', 'dế'], ['🦇', 'dơi'], ['🍓', 'dâu'], ['🍈', 'dưa'], ['🍍', 'dứa'], ['🥥', 'dừa'], ['🌂', 'dù che mưa'], ['🎸', 'đàn ghi ta'], ['🎹', 'đàn piano'], ['🎻', 'đàn vi ô lông']],
            273: [['🍑', 'đào'], ['🥢', 'đôi đũa'], ['🏮', 'đèn'], ['🌃', 'đêm']],
            101: [['🐸', 'ếch']],
            103: [['🐓', 'gà'], ['🐓', 'gà trống'], ['💨', 'gió'], ['👟', 'giày'], ['🛏️', 'giường']],
            104: [['🐖', 'heo'], ['🐅', 'hổ'], ['🌺', 'hoa'], ['🎤', 'hát'], ['🖼️', 'hình']],
            105: [['🖨️', 'in'], ['🤫', 'im lặng']],
            107: [['🐒 ', 'khỉ'], ['🍦', 'kem'], ['🍍', 'khóm'], ['🍭', 'kẹo'], ['🧣', 'khăn'], ['👓', 'kính'], ['🎺', 'kèn']],
            108: [['🐖', 'lợn'], ['🍃', 'lá'], ['🍐', 'lê'], ['📅', 'lịch']],
            109: [['🐈', 'mèo'], ['☁️', 'mây'], ['☔️', 'mưa'], ['🍜', 'mì'], ['👄', 'mồm'], ['👄', 'miệng'], ['👃', 'mũi'], ['👀', 'mắt'], ['👒', 'mũ']],
            110: [['🐎', 'ngựa'], ['🕷', 'nhện'], ['🍄', 'nấm'], ['🦔', 'nhím'], ['🍇', 'nho'], ['🌽', 'ngô'], ['🏠', 'nhà'], ['👒', 'nón'], ['🎵', 'nhạc'], ['⛰️', 'núi'], ['🌊', 'nước']],
            111: [['🐌', 'ốc'], ['🐝', 'ong'], ['🌂', 'ô']],
            112: [['🧀', 'phô mai'], ['🎥', 'phim'], ['🔋', 'pin']],
            113: [['👖', 'quân dai'], ['👖', 'quần dài'], ['👖', 'quần jean']],
            114: [['🐢', 'rùa'], ['🐉', 'rồng'], ['🐍', 'rắn'], ['🍾', 'rượu'], ['🦷', 'răng']],
            115: [['⭐️', 'sao'], ['🐊', 'sấu'], ['🎨', 'sơn'], ['📚', 'sách']],
            116: [['🦐', 'tôm'], ['🐇', 'thỏ'], ['❄️', 'tuyết'], ['🎍', 'tre'], ['🍎', 'táo'], ['🍳', 'trứng'], ['🍵', 'trà'], ['📺', 'tivi'], ['✉️', 'thư'], ['✋', 'tay'], ['⛵️', 'thuyền'], ['💰', 'tiền']],
            117: [['👩‍👦', 'út'], ['🇦🇺', 'Úc']],
            118: [['🐘', 'voi'], ['🦆', 'vịt'], ['🏢', 'văn phòng'], ['🎫', 'vé']],
            120: [['🥗', 'xà lách'], ['🚗', 'xe hơi'], ['🛵', 'xe máy'], ['🚂', 'xe lửa'], ['🦴', 'xương'], ['🧼', 'xà bông']],
            121: [['💗', 'yêu']]
        }
    }
};