export default {
  meta: {
    label: 'Chinese 中文'
  },
  data: {
    zh: {
      97: [['😘', '爱']],
      98: [
        ['🦎', '壁虎'],
        ['🌊', '波浪'],
        ['🦓', '斑马'],
        ['📒', '笔记'],
        ['⚾️', '棒球']
      ],
      99: [['🌲🌲🌲', '丛林']],
      100: [['🐘', '大象'], ['🔪', '刀']],
      101: [['🦢', '鹅'], ['🐊', '鳄鱼']],
      102: [['🏡', '房子'], ['🍚', '饭']],
      103: [['🐶', '狗'], ['👻', '鬼'], ['🏹', '弓箭']],
      104: [['🚣', '划船'], ['🌹', '花儿'], ['🖼', '画'], ['🚀', '火箭']],
      105: [['😞', '哎']],
      106: [['🤖', '机器人'], ['👣', '脚印'], ['🥚', '鸡蛋']],
      107: [['😊', '开心'], ['👀', '看']],
      108: [['🐯', '老虎'], ['🐲', '龙'], ['🍐', '梨子']],
      109: [['🐴', '马'], ['🚪', '门']],
      110: [['🐂', '牛'], ['🐦', '鸟']],
      111: [['😯', '哦(⊙o⊙)']],
      112: [['🏃', '跑步'], ['🍎', '苹果']],
      113: [['💰', '钱'], ['👍', '强']],
      114: [['👎', '弱']],
      115: [['🦁', '狮子'], ['🍣', '寿司'], ['🌿', '树叶']],
      116: [['🐇', '兔子'], ['💃', '跳舞'], ['🦵', '踢'], ['☯️', '太极']],
      117: [['U盘']],
      118: [['v']],
      119: [['🛰️', '卫星'], ['❓', '问号'], ['🤩', '哇']],
      120: [['🐼', '熊猫'], ['🍉', '西瓜'], ['✨', '星星']],
      121: [['☂️', '雨伞'], ['💊', '药']],
      122: [['🐙', '章鱼'], ['🚶', '走路']]
    }
  }
};
