export const Games = {
	Values: {
		ALPHABET: 'alphabet',
		COUNTING: 'counting',
		SPELLING: 'spelling',
	},
	List: [
		{
			label: 'Alphabet',
			key: 'alphabet',
		},
		{
			label: 'Counting',
			key: 'counting',
		},
		{
			label: 'Spelling',
			key: 'spelling',
		},
	]
}
