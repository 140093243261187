export default {
    48: { letter: '0' },
    49: { letter: '1' },
    50: { letter: '2' },
    51: { letter: '3' },
    52: { letter: '4' },
    53: { letter: '5' },
    54: { letter: '6' },
    55: { letter: '7' },
    56: { letter: '8' },
    57: { letter: '9' },
    97: { letter: 'a' },
    98: { letter: 'b' },
    99: { letter: 'c' },
    100: { letter: 'd' },
    101: { letter: 'e' },
    102: { letter: 'f' },
    103: { letter: 'g' },
    104: { letter: 'h' },
    105: { letter: 'i' },
    106: { letter: 'j' },
    107: { letter: 'k' },
    108: { letter: 'l' },
    109: { letter: 'm' },
    110: { letter: 'n' },
    111: { letter: 'o' },
    112: { letter: 'p' },
    113: { letter: 'q' },
    114: { letter: 'r' },
    115: { letter: 's' },
    116: { letter: 't' },
    117: { letter: 'u' },
    118: { letter: 'v' },
    119: { letter: 'w' },
    120: { letter: 'x' },
    121: { letter: 'y' },
    122: { letter: 'z' }
};
