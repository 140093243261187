export default {
  meta: {
    label: 'Eλληνικά'
  },
  data: {
    'el-GR': {
      97: [['🍎', 'Μήλο'], '👽', '⚓️'],
      98: [['🍌', 'μπανάνα'], '👶', '🦇', '🐝'],
      99: ['🐄', '🐱', '🐈', ['🤠', 'Καουμπόυς'], '🛶'],
      100: ['🐶', ['💃', 'Χορεύω'], '🦌'],
      101: [['🥚', 'Αυγό'], '👁️', '🦅'],
      102: [['🐸', 'βάτραχος'], '🍟', '👣'],
      103: [['🍇', 'σταφύλια'], '👻', '🦍'],
      104: [['🥅', 'Δίχτυ του Χόκευ'], ['🏒', 'Μπαστούνι του Χόκευ'], '🐹', '🌺'],
      105: [['🍦', 'Παγωτό']],
      106: [['🤹🏼‍♀️', 'Ζογκλέρ'], '👖', ['🕹️', 'τηλεχειριστήριο']],
      107: [['🔪', 'Μαχάιρι'], '🔑'],
      108: ['🦁', '🦎', '🐆'],
      109: [['🌝', 'φεγγάρι'], ['📫', 'κουτί ταχυδρομείου'], '🍈', '👄'],
      110: ['👃', ['🎶', 'νότες']],
      111: [['🐙', 'χταπόδι'], '👌'],
      112: ['🥞', '🍐', ['🥘', 'δοχείο']],
      113: [['👸🏻', 'Βασίλισσα'], '🇶🇦', ['❓', 'ερωτηματικό']],
      114: ['🐇', ['🤖', 'ρομπότ'], ['♻️', 'ανακύκλωση'], '🚀'],
      115: ['🐍', '🤳', '🥗', ['⭐️', 'αστέρι']],
      116: ['🦃', '🐯', ['🌮', 'τορτίγιες'], '🐅'],
      117: [['☂️', 'Ομπρέλα'],'🆙', ['🦄', 'μονόκερος']],
      118: [['🎻', 'βιολί'], '🏐', ['🌋', 'ηφαίστειο']],
      119: ['🌊', '🍉', ['🚶', 'περπάτημα']],
      120: ['ξένον'],
      121: [['💴', 'γιέν'], '☯️', ['🧒', 'νέος']],
      122: [['⚡️', 'κεραυνός'], ['🤐', 'φερμουάρ']]
    }
  }
};
